import React from "react";
import styled from '@emotion/styled';
import SearchIcon from './icons/search';

const MenuWrap = styled('div')(({ theme, isHeader }) => ({
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  margin: 'auto',
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(5),
  maxWidth: `${isHeader ? '1350px': 'auto'}`,
}));

const SearchBar = styled('div')(({ theme }) => ({
  '& form': {
    position: 'relative',
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(3),

  '& input': {
    border: '1px solid #b1b1b1',
    borderRadius: '8px',
    fontFamily: 'cnn_sans_display, helveticaneue, Helvetica, Arial, Utkal, sans-serif',
    fontSize: '16px',
    height: '48px',
    width: '100%',
    padding: '0 64px 0 8px',
    boxShadow: 'none',
    color: '#404040',
    padding: '0 calc(64px* 2) 0 8px'
  },

  '& button': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    border: 'none',
    boxShadow: 'none',
    background: '0 0',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    letterSpacing: 0,
    color: '#404040',
    fontFamily: 'cnn_sans_display, helveticaneue, Helvetica, Arial, Utkal, sans-serif',
    fontWeight: 700,
    cursor: 'pointer'
    }
  },
}));

const FooterSubnav = styled.div(({ theme }) => {
  return {
    marginBottom: theme.spacing(3),
  };
});

const SubnavSections = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  margin: 0,
  padding: 0,
  listStyle: 'none'
}));

const SubnavSection = styled('li')(({ theme }) => ({
  padding: '0 12px 0 0',
  marginBottom: theme.spacing(3),
  width: 'calc(100% / 7)',
  [theme.breakpoints.down('lg')]: {
    width: 'calc(100% / 5)',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
    '& div': {
      display: 'block',
    '& a': {
      fontSize: '16px'
    }
    },
    '& ul': {
      display: 'none'
    }
  },
}));

const SubnavSectionTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  '& a': {
    marginBottom: '16px',
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 700,
    color: '#fff !important'
  },
}));

const SubnavSubSection = styled('ul')(({ theme }) => ({
  display: 'block',
  margin: 0,
  marginTop: '12px',
  padding: 0,
  listStyle: 'none',

  '& li': {
    marginTop: theme.spacing(0.5),

    '& a': {
      textDecoration: 'none',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#e6e6e6'
    },
  },
}));

export default ({ header = false }) => (
  <MenuWrap
    data-uri="cms.cnn.com/_components/footer/instances/cnn-v1@published"
    id="pageFooter"
    data-editable='settings'
    className="footer cnn-app-display-none"
    data-analytics-aggregate-events="true"
    isHeader={header}
  >
    <div className="footer__inner"> {/* Section first*/}
      <SearchBar className="search-bar" data-uri="cms.cnn.com/_components/search-bar/instances/new-cnn-footer-v1@published" data-editable="settings">
        <form action="https://www.cnn.com/search" name="q" className="search-bar__form" role="search">
          <input placeholder="Search CNN..." aria-label="Search" className="search-bar__input" type="text" autoComplete="off" name="q" data-analytics-prop-click-action="search-query-edit" />
          <button type="submit" className="search-bar__submit" title="Submit" data-analytics-prop-click-action="search-query-submit">
            <SearchIcon />
          </button>
        </form>
      </SearchBar>
    </div>

    <FooterSubnav className="footer__subnav">
      <nav className="subnav" data-uri="cms.cnn.com/_components/subnav/instances/new-edition-footer-v1@published" data-editable="settings">
        <SubnavSections className="subnav__sections">
          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/us" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/us" data-zjs-component_text="US" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/us" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                US
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/us/crime-and-justice" aria-label="US Crime + Justice" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/us/crime-and-justice" data-zjs-component_text="Crime + Justice" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/us/crime-and-justice" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Crime + Justice
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>

          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/world" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world" data-zjs-component_text="World" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                World
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/africa" aria-label="World Africa" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/africa" data-zjs-component_text="Africa" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/africa" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Africa
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/americas" aria-label="World Americas" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/americas" data-zjs-component_text="Americas" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/americas" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Americas
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/asia" aria-label="World Asia" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/asia" data-zjs-component_text="Asia" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/asia" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Asia
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/australia" aria-label="World Australia" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/australia" data-zjs-component_text="Australia" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/australia" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Australia
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/china" aria-label="World China" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/china" data-zjs-component_text="China" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/china" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  China
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/europe" aria-label="World Europe" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/europe" data-zjs-component_text="Europe" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/europe" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Europe
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/india" aria-label="World India" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/india" data-zjs-component_text="India" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/india" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  India
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/middle-east" aria-label="World Middle East" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/middle-east" data-zjs-component_text="Middle East" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/middle-east" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Middle East
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/world/united-kingdom" aria-label="World United Kingdom" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/world/united-kingdom" data-zjs-component_text="United Kingdom" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/world/united-kingdom" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  United Kingdom
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/politics" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/politics" data-zjs-component_text="Politics" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/politics" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Politics
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/politics/supreme-court" aria-label="Politics SCOTUS" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/politics/supreme-court" data-zjs-component_text="SCOTUS" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/politics/supreme-court" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  SCOTUS
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/politics/congress" aria-label="Politics Congress" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/politics/congress" data-zjs-component_text="Congress" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/politics/congress" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Congress
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/politics/fact-check" aria-label="Politics Facts First" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/politics/fact-check" data-zjs-component_text="Facts First" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/politics/fact-check" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Facts First
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/election/2024" aria-label="Politics 2024 Elections" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/election/2024" data-zjs-component_text="2024 Elections" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/election/2024" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  2024 Elections
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/business" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/business" data-zjs-component_text="Business" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/business" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Business
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/business/tech" aria-label="Business Tech" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/business/tech" data-zjs-component_text="Tech" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/business/tech" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Tech
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/business/media" aria-label="Business Media" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/business/media" data-zjs-component_text="Media" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/business/media" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Media
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/business/financial-calculators" aria-label="Business Calculators" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/business/financial-calculators" data-zjs-component_text="Calculators" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/business/financial-calculators" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Calculators
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/business/videos" aria-label="Business Videos" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/business/videos" data-zjs-component_text="Videos" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/business/videos" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Videos
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>

          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/markets" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/markets" data-zjs-component_text="Markets" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/markets" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Markets
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/markets/premarkets" aria-label="Markets Pre-markets" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/markets/premarkets" data-zjs-component_text="Pre-markets" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/markets/premarkets" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Pre-markets
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/markets/after-hours" aria-label="Markets After-Hours" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/markets/after-hours" data-zjs-component_text="After-Hours" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/markets/after-hours" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  After-Hours
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://money.cnn.com/data/hotstocks/" aria-label="Markets Market Movers" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://money.cnn.com/data/hotstocks/" data-zjs-component_text="Market Movers" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://money.cnn.com/data/hotstocks/" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Market Movers
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/markets/fear-and-greed" aria-label="Markets Fear &amp; Greed" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/markets/fear-and-greed" data-zjs-component_text="Fear &amp; Greed" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/markets/fear-and-greed" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Fear &amp; Greed
                </a>
              </li>
              {/* <li className="subnav__subsection">
                <a href="https://money.cnn.com/data/world_markets/americas/" aria-label="Markets World Markets" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://money.cnn.com/data/world_markets/americas/" data-zjs-component_text="World Markets" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://money.cnn.com/data/world_markets/americas/" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  World Markets
                </a>
              </li> */}
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/business/investing" aria-label="Markets Investing" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/business/investing" data-zjs-component_text="Investing" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/business/investing" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Investing
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/investing/markets-now" aria-label="Markets Markets Now" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/investing/markets-now" data-zjs-component_text="Markets Now" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/investing/markets-now" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Markets Now
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/investing/before-the-bell" aria-label="Markets Before the Bell" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/investing/before-the-bell" data-zjs-component_text="Before the Bell" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/investing/before-the-bell" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Before the Bell
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/business/nightcap" aria-label="Markets Nightcap" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/business/nightcap" data-zjs-component_text="Nightcap" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/business/nightcap" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Nightcap
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>


          {/* <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/opinions" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/opinions" data-zjs-component_text="Opinion" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/opinions" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Opinion
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/opinions/opinion-politics" aria-label="Opinion Political Op-Eds" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/opinions/opinion-politics" data-zjs-component_text="Political Op-Eds" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/opinions/opinion-politics" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Political Op-Eds
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/opinions/opinion-social-issues" aria-label="Opinion Social Commentary" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/opinions/opinion-social-issues" data-zjs-component_text="Social Commentary" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/opinions/opinion-social-issues" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Social Commentary
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection> */}


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/health" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/health" data-zjs-component_text="Health" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/health" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Health
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/interactive/life-but-better/" aria-label="Health Life, But Better" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/interactive/life-but-better/" data-zjs-component_text="Life, But Better" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/interactive/life-but-better/" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Life, But Better
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/health/fitness-life-but-better" aria-label="Health Fitness" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/health/fitness-life-but-better" data-zjs-component_text="Fitness" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/health/fitness-life-but-better" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Fitness
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/health/food-life-but-better" aria-label="Health Food" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/health/food-life-but-better" data-zjs-component_text="Food" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/health/food-life-but-better" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Food
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/health/sleep-life-but-better" aria-label="Health Sleep" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/health/sleep-life-but-better" data-zjs-component_text="Sleep" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/health/sleep-life-but-better" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Sleep
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/health/mindfulness-life-but-better" aria-label="Health Mindfulness" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/health/mindfulness-life-but-better" data-zjs-component_text="Mindfulness" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/health/mindfulness-life-but-better" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Mindfulness
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/health/relationships-life-but-better" aria-label="Health Relationships" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/health/relationships-life-but-better" data-zjs-component_text="Relationships" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/health/relationships-life-but-better" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Relationships
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/entertainment" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/entertainment" data-zjs-component_text="Entertainment" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/entertainment" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Entertainment
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/entertainment/movies" aria-label="Entertainment Movies" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/entertainment/movies" data-zjs-component_text="Movies" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/entertainment/movies" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Movies
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/entertainment/tv-shows" aria-label="Entertainment Television" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/entertainment/tv-shows" data-zjs-component_text="Television" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/entertainment/tv-shows" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Television
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/entertainment/celebrities" aria-label="Entertainment Celebrity" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/entertainment/celebrities" data-zjs-component_text="Celebrity" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/entertainment/celebrities" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Celebrity
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/business/tech" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/business/tech" data-zjs-component_text="Tech" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/business/tech" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Tech
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/tech/innovate" aria-label="Tech Innovate" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/tech/innovate" data-zjs-component_text="Innovate" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/tech/innovate" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Innovate
                </a>
              </li>
              {/* <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/tech/gadget" aria-label="Tech Gadget" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/tech/gadget" data-zjs-component_text="Gadget" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/tech/gadget" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Gadget
                </a>
              </li> */}
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/tech/foreseeable-future" aria-label="Tech Foreseeable Future" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/tech/foreseeable-future" data-zjs-component_text="Foreseeable Future" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/tech/foreseeable-future" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Foreseeable Future
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/tech/mission-ahead" aria-label="Tech Mission: Ahead" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/tech/mission-ahead" data-zjs-component_text="Mission: Ahead" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/tech/mission-ahead" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Mission: Ahead
                </a>
              </li>
              {/* <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/tech/upstarts" aria-label="Tech Upstarts" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/tech/upstarts" data-zjs-component_text="Upstarts" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/tech/upstarts" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Upstarts
                </a>
              </li> */}
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/tech/work-transformed" aria-label="Tech Work Transformed" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/tech/work-transformed" data-zjs-component_text="Work Transformed" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/tech/work-transformed" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Work Transformed
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/tech/innovative-cities" aria-label="Tech Innovative Cities" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/tech/innovative-cities" data-zjs-component_text="Innovative Cities" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/tech/innovative-cities" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Innovative Cities
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>

          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/style" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/style" data-zjs-component_text="Style" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/style" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Style
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/style/arts" aria-label="Style Arts" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/style/arts" data-zjs-component_text="Arts" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/style/arts" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Arts
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/style/design" aria-label="Style Design" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/style/design" data-zjs-component_text="Design" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/style/design" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Design
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/style/fashion" aria-label="Style Fashion" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/style/fashion" data-zjs-component_text="Fashion" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/style/fashion" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Fashion
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/style/architecture" aria-label="Style Architecture" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/style/architecture" data-zjs-component_text="Architecture" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/style/architecture" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Architecture
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/style/luxury" aria-label="Style Luxury" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/style/luxury" data-zjs-component_text="Luxury" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/style/luxury" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Luxury
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/style/beauty" aria-label="Style Beauty" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/style/beauty" data-zjs-component_text="Beauty" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/style/beauty" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Beauty
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/style/videos" aria-label="Style Video" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/style/videos" data-zjs-component_text="Video" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/style/videos" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Video
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>



          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/travel" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/travel" data-zjs-component_text="Travel" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/travel" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Travel
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/travel/destinations" aria-label="Travel Destinations" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/travel/destinations" data-zjs-component_text="Destinations" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/travel/destinations" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Destinations
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/travel/food-and-drink" aria-label="Travel Food &amp; Drink" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/travel/food-and-drink" data-zjs-component_text="Food &amp; Drink" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/travel/food-and-drink" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Food &amp; Drink
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/travel/stay" aria-label="Travel Stay" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/travel/stay" data-zjs-component_text="Stay" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/travel/stay" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Stay
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/travel/news" aria-label="Travel News" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/travel/news" data-zjs-component_text="News" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/travel/news" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  News
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/travel/videos" aria-label="Travel Videos" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/travel/videos" data-zjs-component_text="Videos" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/travel/videos" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Videos
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/sports" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/sports" data-zjs-component_text="Sports" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/sports" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Sports
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://bleacherreport.com/nfl" target="_blank" aria-label="Sports Pro Football" className="subnav__subsection-link" rel="noopener noreferrer" data-zjs="click" data-zjs-component_id="https://bleacherreport.com/nfl" data-zjs-component_text="Pro Football" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://bleacherreport.com/nfl" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Pro Football
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://bleacherreport.com/college-football" target="_blank" aria-label="Sports College Football" className="subnav__subsection-link" rel="noopener noreferrer" data-zjs="click" data-zjs-component_id="https://bleacherreport.com/college-football" data-zjs-component_text="College Football" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://bleacherreport.com/college-football" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  College Football
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://bleacherreport.com/nba" target="_blank" aria-label="Sports Basketball" className="subnav__subsection-link" rel="noopener noreferrer" data-zjs="click" data-zjs-component_id="https://bleacherreport.com/nba" data-zjs-component_text="Basketball" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://bleacherreport.com/nba" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Basketball
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://bleacherreport.com/mlb" target="_blank" aria-label="Sports Baseball" className="subnav__subsection-link" rel="noopener noreferrer" data-zjs="click" data-zjs-component_id="https://bleacherreport.com/mlb" data-zjs-component_text="Baseball" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://bleacherreport.com/mlb" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Baseball
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://bleacherreport.com/world-football" target="_blank" aria-label="Sports Soccer" className="subnav__subsection-link" rel="noopener noreferrer" data-zjs="click" data-zjs-component_id="https://bleacherreport.com/world-football" data-zjs-component_text="Soccer" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://bleacherreport.com/world-football" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Soccer
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/sport/paris-olympics-2024" aria-label="Sports Olympics" className="subnav__subsection-link" rel="noopener noreferrer" data-zjs="click" data-zjs-component_id="https://www.cnn.com/sport/paris-olympics-2024" data-zjs-component_text="Olympics" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/sport/paris-olympics-2024" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Olympics
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://bleacherreport.com/nhl" target="_blank" aria-label="Sports Hockey" className="subnav__subsection-link" rel="noopener noreferrer" data-zjs="click" data-zjs-component_id="https://bleacherreport.com/nhl" data-zjs-component_text="Hockey" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://bleacherreport.com/nhl" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Hockey
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/videos" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/videos" data-zjs-component_text="Watch" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/videos" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Watch
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/live-tv" aria-label="Watch Live TV" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/live-tv" data-zjs-component_text="Live TV" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/live-tv" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Live TV
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/videos/fast/cnn-headlines" aria-label="Watch CNN Headlines" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/videos/fast/cnn-headlines" data-zjs-component_text="CNN Headlines" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/videos/fast/cnn-headlines" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  CNN Headlines
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/shorts/cnn-shorts" aria-label="Watch CNN Shorts" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/shorts/cnn-shorts" data-zjs-component_text="CNN Shorts" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/shorts/cnn-shorts" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  CNN Shorts
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/videos/tv/all-shows" aria-label="Watch Shows A-Z" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/videos/tv/all-shows" data-zjs-component_text="Shows A-Z" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/videos/tv/all-shows" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Shows A-Z
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn10" aria-label="Watch CNN10" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn10" data-zjs-component_text="CNN10" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn10" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  CNN10
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.max.com/channel/cnn-max" target="_blank" rel="noopener noreferrer" aria-label="Watch CNN Max" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.max.com/channel/cnn-max" data-zjs-component_text="CNN Max" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.max.com/channel/cnn-max" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  CNN Max
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>

          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/audio" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/audio" data-zjs-component_text="Listen" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/audio" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Listen
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
            </SubnavSubSection>
          </SubnavSection>


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/cnn-underscored" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored" data-zjs-component_text="CNN Underscored" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                CNN Underscored
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/electronics" aria-label="CNN Underscored Electronics" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/electronics" data-zjs-component_text="Electronics" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/electronics" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Electronics
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/fashion" aria-label="CNN Underscored Fashion" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/fashion" data-zjs-component_text="Fashion" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/fashion" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Fashion
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/beauty" aria-label="CNN Underscored Beauty" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/beauty" data-zjs-component_text="Beauty" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/beauty" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Beauty
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/health-fitness" aria-label="CNN Underscored Health &amp; Fitness" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/health-fitness" data-zjs-component_text="Health &amp; Fitness" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/health-fitness" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Health &amp; Fitness
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/home" aria-label="CNN Underscored Home" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/home" data-zjs-component_text="Home" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/home" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Home
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/reviews" aria-label="CNN Underscored Reviews" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/reviews" data-zjs-component_text="Reviews" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/reviews" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Reviews
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/deals" aria-label="CNN Underscored Deals" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/deals" data-zjs-component_text="Deals" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/deals" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Deals
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/money" aria-label="CNN Underscored Money" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/money" data-zjs-component_text="Money" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/money" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Money
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/gifts" aria-label="CNN Underscored Gifts" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/gifts" data-zjs-component_text="Gifts" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/gifts" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Gifts
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/travel" aria-label="CNN Underscored Travel" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/travel" data-zjs-component_text="Travel" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/travel" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Travel
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/outdoors" aria-label="CNN Underscored Outdoors" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/outdoors" data-zjs-component_text="Outdoors" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/outdoors" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Outdoors
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/cnn-underscored/pets" aria-label="CNN Underscored Pets" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/cnn-underscored/pets" data-zjs-component_text="Pets" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/cnn-underscored/pets" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Pets
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://store.cnn.com/?utm_source=cnn.com&amp;utm_medium=referral&amp;utm_campaign=navbar" aria-label="CNN Underscored CNN Store" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://store.cnn.com/?utm_source=cnn.com&amp;utm_medium=referral&amp;utm_campaign=navbar" data-zjs-component_text="CNN Store" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://store.cnn.com/?utm_source=cnn.com&amp;utm_medium=referral&amp;utm_campaign=navbar" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  CNN Store
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>

          {/* <SubnavSection className="subnav__section subnav__section--collapsible subnav__section--collapsed">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://coupons.cnn.com/" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://coupons.cnn.com/" data-zjs-component_text="Coupons" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://coupons.cnn.com/" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Coupons
              </a>
              <button className="subnav__toggle-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.406 7.828L12 12.422l4.594-4.594L18 9.234l-6 6-6-6z"></path></svg>
              </button>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://coupons.cnn.com/about" aria-label="Coupons About CNN Coupons" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://coupons.cnn.com/about" data-zjs-component_text="About CNN Coupons" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://coupons.cnn.com/about" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  About CNN Coupons
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://coupons.cnn.com/help-faq" aria-label="Coupons Frequently Asked Questions" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://coupons.cnn.com/help-faq" data-zjs-component_text="Frequently Asked Questions" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://coupons.cnn.com/help-faq" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Frequently Asked Questions
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://coupons.cnn.com/top20" aria-label="Coupons Top 20 Shops" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://coupons.cnn.com/top20" data-zjs-component_text="Top 20 Shops" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://coupons.cnn.com/top20" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Top 20 Shops
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://coupons.cnn.com/categories" aria-label="Coupons Categories" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://coupons.cnn.com/categories" data-zjs-component_text="Categories" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://coupons.cnn.com/categories" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Categories
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection> */}


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/weather" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/weather" data-zjs-component_text="Weather" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/weather" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                Weather
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/world/cnn-climate" aria-label="Weather Climate" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/world/cnn-climate" data-zjs-component_text="Climate" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/world/cnn-climate" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Climate
                </a>
              </li>
              {/* <li className="subnav__subsection">
                <a href="https://www.cnn.com/interactive/storm-tracker" aria-label="Weather Storm Tracker" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/interactive/storm-tracker" data-zjs-component_text="Storm Tracker" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/interactive/storm-tracker" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Storm Tracker
                </a>
              </li> */}
              {/* <li className="subnav__subsection">
                <a href="https://www.cnn.com/interactive/2020/weather/wildfire-and-air-quality-tracker/" aria-label="Weather Wildfire Tracker" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/interactive/2020/weather/wildfire-and-air-quality-tracker/" data-zjs-component_text="Wildfire Tracker" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/interactive/2020/weather/wildfire-and-air-quality-tracker/" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Wildfire Tracker
                </a>
              </li> */}
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/weather/video" aria-label="Weather Video" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/weather/video" data-zjs-component_text="Video" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/weather/video" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Video
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>


          <SubnavSection className="subnav__section">
            <SubnavSectionTitle className="subnav__section-top">
              <a href="https://www.cnn.com/about" className="subnav__section-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/about" data-zjs-component_text="About CNN" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/about" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                About CNN
              </a>
            </SubnavSectionTitle>
            <SubnavSubSection className="subnav__subsections">
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/photos" aria-label="About CNN Photos" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/photos" data-zjs-component_text="Photos" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/photos" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Photos
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/cnn-investigates" aria-label="About CNN Investigations" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/cnn-investigates" data-zjs-component_text="Investigations" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/cnn-investigates" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Investigations
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/profiles" aria-label="About CNN CNN Profiles" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/profiles" data-zjs-component_text="CNN Profiles" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/profiles" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  CNN Profiles
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/specials/more/cnn-leadership" aria-label="About CNN CNN Leadership" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/specials/more/cnn-leadership" data-zjs-component_text="CNN Leadership" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/specials/more/cnn-leadership" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  CNN Leadership
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://www.cnn.com/email/subscription" aria-label="About CNN CNN Newsletters" className="subnav__subsection-link" data-zjs="click" data-zjs-component_id="https://www.cnn.com/email/subscription" data-zjs-component_text="CNN Newsletters" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://www.cnn.com/email/subscription" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  CNN Newsletters
                </a>
              </li>
              <li className="subnav__subsection">
                <a href="https://careers.wbd.com/cnnjobs" target="_blank" aria-label="About CNN Work for CNN" className="subnav__subsection-link" rel="noopener noreferrer" data-zjs="click" data-zjs-component_id="https://careers.wbd.com/cnnjobs" data-zjs-component_text="Work for CNN" data-zjs-component_type="link" data-zjs-container_id="" data-zjs-container_type="navigation" data-zjs-destination_url="https://careers.wbd.com/cnnjobs" data-zjs-page_type="section" data-zjs-page_variant="landing_homepage" data-zjs-navigation-type="sub" data-zjs-navigation-location="">
                  Work for CNN
                </a>
              </li>
            </SubnavSubSection>
          </SubnavSection>

        </SubnavSections>
      </nav>
    </FooterSubnav>
  </MenuWrap>
);
